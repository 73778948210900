.about-container {
    margin: 0 auto;
    font-optical-sizing: auto;
    font-weight: 400;
}
  
  .about-content {
    padding: 30px;
    max-width: 1000px;
    background: 0;
    padding-bottom: 10px;
  }
  
  h1 {
    color: #333;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
  }
  
  h2 {
    color: #444;
    margin-top: 30px;
  }
  
  p {
    line-height: 1.6;
    color: #666;
  }
  
  ul {
    margin-left: 10px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  a {
    color: #0066cc;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .back-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0066cc;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #0052a3;
    text-decoration: none;
  }

  .call {
    font-weight: 800;
    color:#7a00a3
  }

  .summary-top {
    font-weight: 600;
    font-size: 1.3em;
  }

  .back-button-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
  }