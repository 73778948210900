.conversation {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.conversation-header {
  background-color: #ffffff;
  border-bottom: 1px solid #cfdae1;
  padding: 15px;
}

.message-input {
  margin-top: 0;
}

.conversation-header h2 {
  margin: 0;
  font-size: 18px;
}

.message-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  flex-direction: column-reverse;
  background-color: #f8f9fa;
}

.message {
  max-width: 70%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  clear: both;
}

.message.other-user {
  float: left;
  background-color: #e0e0e0;
}

.message.current-user {
  float: right;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.message-input {
  display: flex;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #cfdae1;
}

.message-input input {
  flex-grow: 1;
  border: 1px solid #cfdae1;
  border-radius: 4px;
  padding: 8px;
  margin-right: 10px;
}

.message-input button {
  background-color: #6ea0ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
}

.message-input button:hover {
  background-color: #5b8fe6;
}

@media (max-width: 768px) {
  .message {
    max-width: 85%;
  }

  .message-input {
    padding: 10px;
  }

  .message-input input {
    font-size: 16px; /* Prevents zoom on focus in iOS */
  }

  .message-input button {
    padding: 8px 12px;
    font-size: 14px;
  }
}