.ListingCard.listing-card {
  border: 1px solid black;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 18rem;
  max-width: 18rem;
  margin: 1rem;
  flex-direction: row;
}

.ListingCard.listing-card:hover {
  transform: translateY(-5px);
}

.ListingCard .listing-image-container {
  width: 100%;
  height: 18rem;
  overflow: hidden;
}

.ListingCard .listing-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ListingCard .listing-details {
  padding: 12px;
}

.ListingCard .listing-price {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0 8px 0;
  color: #333;
}

.ListingCard .listing-title {
  font-size: 1rem;
  margin: 0;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
