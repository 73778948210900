.profile-content {
    min-height: 100vh;
    background-color:#f0f0f0;
    padding: 20px;
}

.profileSection {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
}

.listingsSection {
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
    overflow: hidden;
}

.messagesSection {
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.sectionTitle {
    font-size: 1.5rem;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

.profileInfo {
    margin-bottom: 20px;
}

form {
    margin-top: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.updateButton {
    padding: 10px;
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.logoutButton {
    padding: 10px;
    background-color: #db4437;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.listingsContainer {
    gap: 15px;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.placeholderText {
    grid-column: 1 / -1;
    text-align: center;
    color: #888;
}

/* Ensure ListingCard fits within the grid */
.listingCard {
    max-width: 200px;
}

.listingsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}