.message {
    max-width: 70%;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    clear: both;
  }
  
  .message.other-user {
    float: left;
    background-color: #e4eaee;
  }
  
  .message.current-user {
    float: right;
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .message-content {
    margin-bottom: 5px;
  }
  
  .message-content p {
    margin: 0;
    color: #333f4d;
  }
  
  .message-info {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    color: #6c757d;
  }
  
  .sender-name {
    font-weight: bold;
  }
  
  .message-time {
    font-style: italic;
  }