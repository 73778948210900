/* #title-img {
  max-width: 100px;
} */

#button-addon2, #items-header {
  background-color: #8205ff;
}

#magnifying-glass {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}

.header-buttons {
  white-space: nowrap;
}

#search-bar {
  max-width: 600px;
}

#cover-img {
    width: 100%;
    height: auto;
    display: block;
}

#items-header button, #sell-btn {
  font-weight: bold;
}