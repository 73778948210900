/* Header styles */
header {
    position: sticky;
    top: 0;
    /* z-index: 1000; */
    background-color: #fff;
  }
  
  /* Mobile menu styles */
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1001;
    padding: 20px;
  }
  
  .mobile-menu .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .mobile-menu nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .mobile-menu nav ul li {
    margin-bottom: 20px;
  }
  
  .mobile-menu nav ul li a {
    font-size: 18px;
    text-decoration: none;
    color: #333;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .header-buttons .btn span {
      display: none;
    }
  }

  .huskyswap-btn {
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #8205ff !important;
  }