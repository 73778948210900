.listing-section {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: baseline;
    flex-wrap: wrap;
}

.listing-container {
    max-width: 300px;
}

.footer {
    background-color: #f0f0f0;
    color: black;
    border-top: 1px solid black;
}